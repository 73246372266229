export class QueryParamsMapperService {
  static getProxyQueryParams() {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string) => searchParams.get(prop),
    });
  }

  static getQueryParamsObject() {
    return QueryParamsMapperService.toQueryParamsObject(QueryParamsMapperService.getProxyQueryParams());
  }

  static toQueryParamsObject(params) {
    return {
      includeDynamic: params['includeDynamic'],
      promoCode: params['promoCode'] || params['promocode'],
      bundleCode: params['bundleCode'] || params['bundlecode'],
      simulationId: params['simulationId'],
      utm_campaign: params['utm_campaign'],
      utm_source: params['utm_source'],
      utm_medium: params['utm_medium'],
      utm_content: params['utm_content'],
      utm_term: params['utm_term'],
      gclid: params['gclid'],
      dclid: params['dclid'],
      sponsorReference: params['sponsorReference']
    };
  }

  static getQueryParam(value) {
    const params = QueryParamsMapperService.getProxyQueryParams();
    return params[value];
  }
}
