import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TypeDetectorService } from '../../../../core/service/type-detector.service';
import { Type } from '../../../../core/state/state';
import { Observable, zip } from 'rxjs';
import { BundlesListService } from '../bundles-list.service';
import { first, map, switchMap } from 'rxjs/operators';
import {
  BundleAmount,
  BundleDetails,
  BundleType,
  BundleView,
  Bundles,
  LocalizedValue,
  PromotionDetails,
} from 'src/app/core/bundle/bundle';

@Component({
  selector: 'app-bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BundleComponent {
  bundleView$: Observable<BundleView>;
  @Input() set bundleDetails(bundleDetails: BundleDetails) {
    this.bundleView$ = this.toBundleView(bundleDetails);
  }

  @Output() becomeCustomerClicked: EventEmitter<BundleView> = new EventEmitter<BundleView>();

  isB2c = TypeDetectorService.detectType() === Type.B2C;
  bundleTypes = BundleType;

  constructor(
    public translateService: TranslateService,
    private readonly productListService: BundlesListService,
  ) {}

  moreInfo(moreInfo: LocalizedValue): void {
    if (moreInfo !== null) {
      window.open(this.translateService.currentLang === 'fr' ? moreInfo.fr : moreInfo.nl, '_blank');
    }
  }

  onTariffToggled(bundleView: BundleView): void {
    this.productListService
      .findBundle(bundleView.linkedBundle.bundleType, bundleView.linkedBundle.code)
      .subscribe(
        () =>
          (this.bundleView$ = this.productListService
            .getBundleDetailsFrom(bundleView.linkedBundle.code)
            .pipe(switchMap((bundleDetails) => this.toBundleView(bundleDetails)))),
      );
  }

  private toBundleView(bundleDetails: BundleDetails): Observable<BundleView> {
    return zip(
      this.productListService.getBundlesFrom(bundleDetails.code),
      this.productListService.getBundlePriceInfo(bundleDetails.code, bundleDetails.commercialCode),
      this.productListService.getPromoDetails(bundleDetails.code, bundleDetails.commercialCode),
    ).pipe(
      first(),
      map(([bundle, priceInfo, promoDetails]: [Bundles | null, BundleAmount, PromotionDetails]) => ({
        totalAmount: priceInfo.totalAmount,
        totalPromoAmount: priceInfo.totalPromoAmount,
        electricityAmount: priceInfo.electricityAmount,
        electricityPromoAmount: priceInfo.electricityPromoAmount,
        gasAmount: priceInfo.gasAmount,
        gasPromoAmount: priceInfo.gasPromoAmount,
        commercialCode: bundleDetails.commercialCode,
        code: bundleDetails.code,
        picture: bundleDetails.picture,
        color: bundleDetails.color,
        gradient: bundleDetails.gradient,
        commercialLinks: bundleDetails.commercialLinks,
        description: bundleDetails.description,
        promo: {
          title: promoDetails?.title,
          description: promoDetails?.description,
        },
        linkedBundle: bundle?.linkedBundle,
        bundleType: bundle?.bundleType,
        weighting: bundleDetails.weighting,
        bundleLinked: bundle?.linkedBundle != null,
      })),
    );
  }
}
