import { Energy, MeterType, Site } from '../../state/state';
import { isBoolean } from '../../utils';

export class OlregB2CMapperService {
  static toOlregQueryParams(
    bundleCode: string,
    site: Site,
    promoCode: string,
    sponsorReference: string,
    geep: string,
    agentUserName: string,
    includeDynamic: string,
    simulationId: string,
  ): URLSearchParams {
    const urlParameters = new URLSearchParams();
    urlParameters.append('comingFromComparator', 'false');
    urlParameters.append('comingFromTotalEnergiesSimulator', 'true');
    urlParameters.append('bundleCode', bundleCode);
    urlParameters.append('energy', site.energy);
    urlParameters.append('deliveryZipCode', String(site.zipCode));
    urlParameters.append('deliveryTown', site.town);
    urlParameters.append('simulationId', simulationId);
    urlParameters.append('elecMeterType', String(site.meterType || MeterType.MONO));
    // urlParameters.append('simulationId', site.simulationId);

    if (promoCode) {
      urlParameters.append('promoCode', promoCode);
    }
    if (sponsorReference) {
      urlParameters.append('sponsorReference', sponsorReference);
    }
    if (geep) {
      urlParameters.append('isGeep', geep);
    }
    if (agentUserName) {
      urlParameters.append('agentCode', agentUserName);
    }
    if (includeDynamic) {
      urlParameters.append('includeDynamic', includeDynamic);
    }
    if (site.averagePeak) {
      urlParameters.append('averagePeak', String(site.averagePeak));
    }
    if (site.energy !== Energy.GAS) {
      urlParameters.append('hasElectricVehicle', String(site.hasElectricVehicle));
      urlParameters.append('hasSolarPanels', String(site.hasSolarPanels));
      if (site.hasSolarPanels) {
        urlParameters.append('solarPanelKva', String(site.solarPanelKva));
      }
    }
    if (site.exclusiveNight) {
      urlParameters.append('exclusiveNight', String(site.exclusiveNight));
    }
    if (site.smartMeter) {
      urlParameters.append('smartMeter', String(site.smartMeter));
    }
    if (site.consumptions.consumptionHigh || site.consumptions.consumptionMono) {
      urlParameters.append('elecAnnualConsumption', this.getAnnualConsumption(site));
    }
    if (site.consumptions.consumptionLow) {
      urlParameters.append('elecAnnualConsumptionNight', String(site.consumptions.consumptionLow));
    }
    if (site.consumptions.consumptionExclusiveNight) {
      urlParameters.append('elecAnnualConsumptionExclusiveNight', String(site.consumptions.consumptionExclusiveNight));
    }
    if (site.consumptions.gasConsumption) {
      urlParameters.append('gasAnnualConsumption', Number(site.consumptions.gasConsumption).toFixed(0));
    }
    if (site.consumptions.injectionHigh || site.consumptions.injectionMono) {
      urlParameters.append('elecAnnualInjection', this.getAnnualInjection(site).toFixed(0));
    }
    if (site.consumptions.injectionLow) {
      urlParameters.append('elecAnnualInjectionNight', Number(site.consumptions.injectionLow).toFixed(0));
    }
    if (isBoolean(site.injectionTariff)) {
      urlParameters.append('injectionTariff', String(site.injectionTariff));
    }
    if (isBoolean(site.solarPanelsInstallationDateStarting2024)) {
      urlParameters.append('solarPanelsInstallationDateStarting2024', String(site.solarPanelsInstallationDateStarting2024));
    }

    return urlParameters;
  }

  private static getAnnualInjection(site: Site) {
    return Number(
      site.meterType === MeterType.MONO ? site.consumptions.injectionMono : site.consumptions.injectionHigh,
    );
  }

  private static getAnnualConsumption(site: Site) {
    return String(
      site.meterType === MeterType.MONO ? site.consumptions.consumptionMono : site.consumptions.consumptionHigh,
    );
  }
}
