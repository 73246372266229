import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Bundles, UnknownConsumptions } from '../bundle/bundle';
import { Energy, Consumptions } from '../state/state';

@Injectable({
  providedIn: 'root',
})
export class SimulatorEngineApi {
  private readonly SIMULATIONS_URL = `${environment.BACKEND_URL}/v2/simulations`;

  constructor(private readonly http: HttpClient) {}

  post(payload: SimulatorEngineRequest[]): Observable<SimulationEngineResponse> {
    return this.http.post<SimulationEngineResponse>(this.SIMULATIONS_URL, payload).pipe(
      catchError((err: HttpErrorResponse) => {
        throwError('Impossible to get result of simulation');
        return throwError(err);
      }),
    );
  }

  get(simulationId: string): Observable<SimulationEngineResponse> {
    return this.http.get<SimulationEngineResponse>(this.SIMULATIONS_URL + '/' + simulationId);
  }
}

export interface SimulationEngineResponse {
  simulationId: string;
  simulations: SimulationEngineSimulations[];
}

export interface SimulationEngineSimulations {
  simulationRequest: SimulatorEngineRequest;
  bundles: Bundles[];
}
export class SimulatorEngineRequest {
  constructor(
    public zipCode?: number,
    public town?: string,
    public b2b = true,
    public smartMeter?: boolean,
    public hasElectricVehicle?: boolean,
    public hasSolarPanels?: boolean,
    public solarPanelKva?: number,
    public solarPanelsInstallationDateStarting2024?: boolean,
    public injectionTariff?: boolean,
    public salesContext?: string,
    public averagePeak?: number,
    public promoCode?: string,
    public energy?: Energy,
    public siteName?: string,
    public consumptions?: Consumptions,
    public exclusiveNight?: boolean,
    public consumptionHelp?: string,
    public knowConsumption?: boolean,
    public meterType?: string,
    public unknownConsumption?: UnknownConsumptions,
    public includeDynamicBundles?: boolean,
  ) {}
}
