import { SimulatorEngineRequest } from '../../api/simulator-engine.api';
import { MeterType, Site, Type } from '../../state/state';
import { ZipCodeService } from '../../service/zip-code.service';
import { Bundles } from '../../bundle/bundle';

export class SimulatorEngineMapperService {
  static toSiteState(compareEnergy: SimulatorEngineRequest, simulation: Bundles[]): Site {
    return {
      zipCode: compareEnergy.zipCode,
      town: compareEnergy.town,
      smartMeter: compareEnergy.smartMeter,
      hasSolarPanels: compareEnergy.hasSolarPanels,
      solarPanelKva: compareEnergy.solarPanelKva,
      solarPanelsInstallationDateStarting2024: compareEnergy.solarPanelsInstallationDateStarting2024,
      injectionTariff: compareEnergy.injectionTariff,
      averagePeak: compareEnergy.averagePeak,
      hasElectricVehicle: compareEnergy.hasElectricVehicle,
      consumptions: compareEnergy.consumptions,
      meterType: compareEnergy.smartMeter
        ? MeterType[compareEnergy.meterType.replace('SMART', '')]
        : MeterType[compareEnergy.meterType],
      exclusiveNight: compareEnergy.exclusiveNight,
      energy: compareEnergy.energy,
      siteName: compareEnergy.siteName,
      knowConsumption: compareEnergy.knowConsumption,
      simulation: simulation,
      unknownConsumption: compareEnergy.unknownConsumption,
      consumptionHelp: compareEnergy.consumptionHelp,
      includeDynamicBundles: compareEnergy.includeDynamicBundles,
      stepsReached: new Set<string>(),
    };
  }

  static toSimulatorEngineRequest(site: Site, type: Type, promoCode: string, includeDynamic?: string): SimulatorEngineRequest {
    return {
      salesContext: 'OLREG',
      zipCode: site.zipCode,
      town: site.town,
      smartMeter: ((ZipCodeService.isBrussels(site.zipCode) && site.hasSolarPanels) || site.smartMeter) ?? false,
      hasSolarPanels: site.hasSolarPanels ?? false,
      solarPanelKva: site.solarPanelKva,
      solarPanelsInstallationDateStarting2024: site.solarPanelsInstallationDateStarting2024,
      injectionTariff: site.injectionTariff,
      averagePeak: site.averagePeak,
      energy: site.energy,
      meterType: site.meterType,
      hasElectricVehicle: site.hasElectricVehicle ?? false,
      consumptions: site.consumptions,
      b2b: type === Type.B2B,
      promoCode: promoCode,
      siteName: site.siteName,
      exclusiveNight: site.exclusiveNight,
      consumptionHelp: site.consumptionHelp,
      knowConsumption: site.knowConsumption,
      unknownConsumption: site.unknownConsumption,
      includeDynamicBundles: includeDynamic == 'true'
    };
  }
}
